import gql from 'graphql-tag';

export const ALL_LIVE_GAMES_PROMOTED = gql`
  query ALL_LIVE_GAMES_PROMOTED {
    allLiveGamesPromoted(
      where: { active: { eq: true } }
      sort: { orderRank: ASC }
    ) {
      _id
      name
      active
      game {
        _key
        _type
        name
        id
        thumbnail
        slug
      }
      tags
      order
      orderRank
      provider
      providerRef
    }
  }
`;
