import gql from 'graphql-tag';

export const GET_FAQ_Page = gql`
  query getFAQPage {
    allFaqPage(sort: { orderRank: ASC }) {
      _id
      questions {
        question
        answerRaw
      }
      category
    }
  }
`;
