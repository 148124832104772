import gql from 'graphql-tag';

export const ALL_COMPETITION_CATEGORIES = gql`
  query ALL_COMPETITION_CATEGORIES {
    allCompetitionCategories(
      where: { homepageDisplay: { eq: true } }
      sort: { orderRank: ASC }
    ) {
      title
      categoryFilter
      displayMode
      carouselInitialState
      hidePrice
      isFilterAppBased
      order
      orderRank
      excludeFromClosingToday
      homepageDisplay
      loggedInOnly
    }
  }
`;

export const ALL_COMPETITION_CATEGORIES_LOGGED_OUT = gql`
  query ALL_COMPETITION_CATEGORIES_LOGGED_OUT {
    allCompetitionCategories(
      where: { homepageDisplay: { eq: true }, loggedInOnly: { eq: null } }
      sort: { orderRank: ASC }
    ) {
      title
      categoryFilter
      displayMode
      carouselInitialState
      hidePrice
      isFilterAppBased
      order
      orderRank
      excludeFromClosingToday
      homepageDisplay
      loggedInOnly
    }
  }
`;
