import gql from 'graphql-tag';

export const ALL_MODALS = gql`
  query ALL_MODALS($modalId: String!) {
    allModals(where: { modalId: { eq: $modalId } }) {
      _id
      name
      modalId
      heading
      message
      buttonLabel
      showLogo
      messageLink
      messageLinkContent
      footerContent
    }
  }
`;
