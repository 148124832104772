import gql from 'graphql-tag';

export const ALL_PROMOTION_COLLECTIONS = gql`
  query {
    allPromotionCollections {
      pages {
        _id
        _type
        _createdAt
        _updatedAt
        _rev
        _key
        name
        openInModal
        routeName
        metaTitle
        metaDescription
        listImage {
          _key
          _type
          asset {
            _type
            url
          }
        }
        imageDesktop {
          _key
          _type
          asset {
            _type
            url
          }
        }
        contentRaw
        introSummary
        introSummaryDynamicVariable
        promotionId
        introSummarySuffix
        competitionType
        competitionFrequency
        time
        dayOfWeek
        dayOfMonth
        badgeColor
        badgeLabel
        expiryDate
        ongoingCompetetion
        hasCountDown
        games {
          _id
          _type
          _createdAt
          _updatedAt
          _rev
          _key
          title
          slug {
            _key
            _type
            current
            source
          }
          mainImage {
            _key
            _type
            asset {
              _type
              url
            }
          }
          iframeUrl
          rtp
          gameVolatility
        }
        summaryTermsAndConditionsRaw
        termsAndConditionsRaw
      }
    }
  }
`;
