import gql from 'graphql-tag';

export const ALL_SHARING_MESSAGES = gql`
  query ALL_SHARING_MESSAGES($name: String!) {
    allSharingMessages(where: { name: { eq: $name } }) {
      content
      hashtags
    }
  }
`;
