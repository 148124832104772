import gql from 'graphql-tag';

export const ALL_PROMO_BANNERS_COLLECTIONS = gql`
  query ALL_PROMO_BANNERS_COLLECTIONS($collectionId: String!) {
    allPromoBannersCollections(where: { collectionId: { eq: $collectionId } }) {
      collectionId
      show
      page
      banners {
        autoplayTimer
        badgeColor
        badgeContent
        bannerId
        componentOverride
        descriptionRaw
        expiryDate
        imageDesktop {
          _type
          asset {
            url
          }
        }
        imageLayer {
          _type
          asset {
            url
          }
        }
        imageMobile {
          _type
          asset {
            url
          }
        }
        linkTo
        modalButton
        orderRank
        promoContent
        promoTitle
        promoTitleDynamicVariable
        promotionId
        show
        showModal
        _createdAt
        _id
        _rev
        _type
        _updatedAt
      }
    }
  }
`;
