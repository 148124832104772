import { io } from 'socket.io-client';

export const useWebsocketStore = defineStore({
  id: 'websocket-store',
  state: () => {
    return {
      ws: null,
      handlers: {},
      subscriptions: {},
      message: {},
      loggedin: false,
      loginPending: false,
      connectWaitPromise: null,
    };
  },
  getters: {
    /**
     * NB: Not currently used
     */
    websocketConnected: (state) => {
      return !!state.ws?.connected;
    },
  },
  actions: {
    handleMessage(data) {
      const authStore = useAuthStore();
      if (!authStore.isUserLogged) { return; }

      this.message = data;
    },
    async subscribe(topic) {
      if (this.subscriptions[topic]) {
        this.subscriptions[topic]++;
        return;
      }

      this.subscriptions[topic] = 1;

      try {
        await this.websocketSend('subscribe', topic);
      } catch (err) {
        useNuxtApp().$rollbar.error('subscribe, method failed', err);
      }
    },
    async resubscribe() {
      if (Object.keys(this.subscriptions).length === 0) { return; }

      try {
        const topics = Object.keys(this.subscriptions);
        for (const topic of topics) {
          await this.websocketSend('subscribe', topic);
        }
      } catch (err) {
        useNuxtApp().$rollbar.error('resubscribe, method failed', err);
      }
    },
    unsubscribe(topic) {
      setTimeout(() => this.doUnsubscribe(topic), 500);
    },
    async doUnsubscribe(topic) {
      const subCount = this.subscriptions[topic];
      if (!subCount) { return; }

      this.subscriptions[topic]--;

      if (this.subscriptions[topic] > 0) { return; }

      delete this.subscriptions[topic];

      try {
        await this.websocketSend('unsubscribe', topic);
      } catch (err) {
        useNuxtApp().$rollbar.error('doUnsubscribe, method failed', err);
      }
    },
    websocketConnect() {
      const envVars = useRuntimeConfig().public;
      const wsUrl = envVars.WEBSOCKET_URL;
      this.websocketDisconnect();
      this.ws = io(wsUrl, {
        path: '/connect',
        transports: ['websocket',],
        reconnection: true,
        reconnectionAttempts: 100,
        reconnectionDelay: 500,
        reconnectionDelayMax: 2000,
      });

      this.ws.on('message', (message) => {
        const authStore = useAuthStore();

        if (message.type === 'User:SignOut' && authStore.user) { authStore.logout(); }

        this.handleMessage(message);
      });

      this.ws.on('connect', () => {
        this.websocketLogin();
      });

      this.ws.io.on('reconnect', () => {
        this.resubscribe();
      });

      this.ws.on('disconnect', (reason) => {
        this.conncted = false;
      });
    },
    websocketDisconnect() {
      if (!this.ws) { return; }
      this.ws.close(3000);
      this.ws = null;
    },
    async websocketLogin() {
      if (this.loginPending) { return; }

      this.loginPending = true;
      const nuxtApp = useNuxtApp();

      const authStore = useAuthStore();
      await authStore.getUser();
      if (!authStore.user) {
        this.loginPending = false;
        return;
      }

      try {
        await this.websocketSend('login', authStore.user.signInUserSession.accessToken.jwtToken);
        this.loggedin = true;
      } catch (err) {
        nuxtApp.$rollbar.error('websocketLogin method failed', err);
      } finally {
        this.loginPending = false;
      }
    },
    async websocketLogout() {
      try {
        await this.websocketSend('logout');
        this.loggedin = false;
      } catch (err) {
        useNuxtApp().$rollbar.error('websocketLogout method failed', err);
      }
    },
    async websocketSend(event, ...data) {
      if (!this.ws) { return; }
      try {
        const response = await this.ws.emitWithAck(event, ...data);
        if (response.success) { return response.result; }
      } catch (err) {
        useNuxtApp().$rollbar.error('websocketSend method failed', err);
      }
    },
  },
});
