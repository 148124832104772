import gql from 'graphql-tag';

export const ALL_INFO_MODALS = gql`
  query ALL_INFO_MODALS($id: String!) {
    allInfoModals(where: { modalId: { eq: $id } }) {
      _id
      heading
      modalId
      contentRaw
      modalWidthMode
    }
  }
`;
