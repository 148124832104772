/*
Nuxt 3 recommends using $fetch instead of Axios: https://v3.nuxtjs.org/api/utils/$fetch
This custom plugin can be used in pages and in pinia stores via this.$nuxt.$api.get
*/
export default defineNuxtPlugin((nuxtApp) => {
  const authStore = useAuthStore();
  const {
    user,
    isUserLogged,
  } = storeToRefs(authStore);
  const { refreshSessionIfNeeded, } = authStore;

  const apiFetch = $fetch.create({
    baseURL: nuxtApp.$config.public.API_HOST,
    async onRequest({ options, }) {
      const hasLocalStorageToken = localStorage.getItem('CognitoIdentityServiceProvider') !== null;

      if (isUserLogged.value || hasLocalStorageToken) {
        try {
          await refreshSessionIfNeeded();
        } catch (err) {
          nuxtApp.$rollbar.error('$fetch.create::: session refresh failed:', err);
        }
      }

      if (user.value?.signInUserSession?.accessToken?.jwtToken) {
        options.headers = new Headers({
          Authorization: `Bearer ${user.value?.signInUserSession?.accessToken?.jwtToken}`,
        });
      }
    },
    async onResponseError({ response, }) {
      const hasLocalStorageToken = localStorage.getItem('CognitoIdentityServiceProvider') !== null;

      if (response.status === 401 && (isUserLogged.value || hasLocalStorageToken)) {
        try {
          await refreshSessionIfNeeded();
        } catch (err) {
          // ignore
        }
      }
    },
  });

  return {
    provide: {
      api: async(url, payload) => {
        try {
          const res = await apiFetch(url, payload);
          return res;
        } catch (err) {
          if (err.data?.status === 401) {
            // If token expired, try to refresh it
            if (err.data?.message === 'Access token expired.' && isUserLogged.value) {
              try {
                // Attempting to refresh token by calling getUser
                const refreshSuccess = await refreshSessionIfNeeded();

                if (refreshSuccess) {
                  // If refresh was successful, retry the original request
                  // Get new token from localStorage or user session
                  return $fetch(url, {
                    ...options,
                    headers: {
                      ...options.headers,
                      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                  });
                } else {
                  // If refresh failed, log out the user
                  return authStore.logout();
                }
              } catch (refreshError) {
                return authStore.logout();
              }
            } else if (isUserLogged.value && err.data?.message !== 'You must be a VIP to access this data.') {
              // Other 401 errors for logged in users (except VIP)
              return authStore.logout();
            }
          }
          throw err;
        }
      },
    },
  };
});
