<template>
  <footer class="bg-slate-900 px-3 py-10 lg:py-6 lg:px-4 2xl:px-6">
    <div class="flex flex-col-reverse justify-center items-center md:flex-row md:items-start md:justify-between md:space-x-4">
      <div class="flex items-center justify-center flex-col md:items-start max-md:hidden h-full">
        <NuxtLink
          to="/"
          :replace="route.fullPath.includes('sports')"
          class="inline-block w-24"
        >
          <img
            src="~/assets/img/logos/MetaWin-logo-white.svg"
            alt="MetaWin logo"
            class="inline-block w-full transition-all"
          >
        </NuxtLink>
        <p class="text-slate-400 text-xs text-center md:text-left mt-1">
          Copyright © {{ footerYear }} MetaWin
        </p>
        <p class="text-slate-400 text-xs text-center md:text-left">
          Patent Pending
        </p>
      </div>
      <div
        class="flex items-center flex-col mt-4 md:mt-0 max-md:order-2"
        :class="[showGamingContent ? 'md:w-2/5 md:items-start' : 'md:w-1/4']"
      >
        <div
          class="max-xs:flex-col flex gap-[52px] max-xs:gap-0 mb-6"
        >
          <div class="mt-1 text-center md:text-left mb-4 md:mb-0">
            <p class="text-white text-sm font-semibold">About us</p>
            <ul class="flex flex-col md:items-start">
              <li v-if="isUserLogged">
                <FooterLink to="/faq">FAQ</FooterLink>
              </li>
              <li>
                <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
              </li>
              <li>
                <FooterLink to="/cookie-policy">Cookie Policy</FooterLink>
              </li>
              <li>
                <FooterLink to="/games/terms-and-conditions">
                  Terms and Conditions
                </FooterLink>
              </li>
              <li>
                <FooterLink to="/sports/terms-and-conditions">
                  Sports Terms and Conditions
                </FooterLink>
              </li>
            </ul>
          </div>
          <div
            v-if="showGamingContent"
            class="mt-1 text-center md:text-left mb-4 md:mb-0"
          >
            <p class="text-white text-sm font-semibold">Gaming</p>
            <ul class="flex flex-col md:items-start">
              <li>
                <FooterLink to="/underage-gaming-policy">
                  Underage Gaming Policy
                </FooterLink>
              </li>
              <li>
                <FooterLink to="/self-exclusion-policy">
                  Self-Exclusion Policy
                </FooterLink>
              </li>
              <li>
                <FooterLink to="/responsible-gambling">
                  Responsible Gambling
                </FooterLink>
              </li>
              <li>
                <FooterLink to="/games/kyc">KYC Policy</FooterLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mb-4 md:mb-0 md:w-2/5">
        <p class="text-white max-md:text-center text-lg font-semibold">
          Join our Web3 community
        </p>
        <div class="flex items-center flex-row justify-center md:justify-start space-x-3 py-2">
          <NuxtLink to="https://discord.gg/the-arena" target="_blank">
            <img
              src="~/assets/img/logos/discord.svg"
              alt="Discord logo"
              class="w-6"
            >
          </NuxtLink>
          <NuxtLink to="https://twitter.com/Meta_Winners" target="_blank">
            <img
              src="~/assets/img/logos/twitter.svg"
              alt="Twitter logo"
              class="w-6"
            >
          </NuxtLink>
          <NuxtLink to="https://www.instagram.com/metawin.eth" target="_blank">
            <img
              src="~/assets/img/logos/instagram.svg"
              alt="Instagram logo"
              class="w-6"
            >
          </NuxtLink>
          <NuxtLink to="https://t.me/metawinlfg" target="_blank">
            <img
              src="~/assets/img/logos/telegram.svg"
              alt="Telegram logo"
              class="w-6"
            >
          </NuxtLink>
        </div>
        <div class="flex flex-col items-center md:hidden my-10">
          <NuxtLink
            to="/"
            :replace="route.fullPath.includes('sports')"
            class="inline-block w-24"
          >
            <img
              src="~/assets/img/logos/MetaWin-logo-white.svg"
              alt="MetaWin logo"
              class="inline-block w-full transition-all"
            >
          </NuxtLink>
          <p class="text-slate-400 text-xs text-center md:text-left mt-1">
            Copyright © {{ footerYear }} MetaWin
          </p>
          <p class="text-slate-400 text-xs text-center md:text-left">
            Patent Pending
          </p>
        </div>
        <div class="space-y-1 mt-2">
          <p
            v-if="isCompetition"
            class="text-slate-400 text-xs text-center mb-4 md:text-left"
          >
            18+. T&C apply. <NuxtLink to="/amoe" :replace="route.fullPath.includes('sports')">No purchase necessary</NuxtLink>.<br class="block md:hidden">Void where prohibited by law.
          </p>
          <p
            v-if="showGamingContent"
            class="text-slate-400 text-xs text-center md:text-left mb-3"
          >
            Metawin.com is owned and operated by Asobi N.V. , ABRAHAM DE VEERSTRAAT 9, WILLEMSTAD, CURACAO and is licensed and regulated by the Government of the Autonomous Island of Anjouan, Union of Comoros and operates under license No. ALSI-1523 12009-FI3. Metawin.com has passed all regulatory compliance and is legally authorized to conduct gaming operations for any and all games of chance and wagering. (company number 153109).<br>
            Zoom Net Services Ltd, a company incorporated in Cyprus (Registration No.HE 410498), provides payment services under an agreement with Asobi N.V.
          </p>
        </div>
        <div
          v-if="showGamingContent"
          class="flex items-center justify-end gap-2 max-md:justify-center order-1 mb-10 mt-4"
        >
          <img
            src="~/assets/img/logos/18+_icon.png"
            alt="+18 logo"
            class="w-6"
          >
          <div
            id="anj-1862be5a-e657-43ea-bbca-a7cfc5e55fdd"
            ref="anjSeal"
            data-anj-seal-id="1862be5a-e657-43ea-bbca-a7cfc5e55fdd"
            data-anj-image-size="40"
            data-anj-image-type="basic-small"
            class="w-10"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
const props = defineProps({
  forceGamingContent: {
    type: Boolean,
    default: false,
  },
  isCompetition: {
    type: Boolean,
    default: false,
  },
});

const anjSeal = ref(null);
const anjSealInit = ref(false);

const { $rollbar, } = useNuxtApp();

const authStore = useAuthStore();
const {
  isUserLogged,
} = storeToRefs(authStore);

const locationStore = useLocationStore();
const {
  isGamingRestricted,
} = storeToRefs(locationStore);

const { isFeatureEnabled, } = useFeatureFlag('anjouan-license');

const route = useRoute();

const showGamingContent = computed(() => props.forceGamingContent || !isGamingRestricted.value);
const footerYear = ref(new Date().getFullYear());

watch(anjSeal, (domElement) => {
  if (!isFeatureEnabled.value || !domElement || anjSealInit.value) { return; }
  setTimeout(() => {
    try {
      if (window?.anj_1862be5a_e657_43ea_bbca_a7cfc5e55fdd) {
        window?.anj_1862be5a_e657_43ea_bbca_a7cfc5e55fdd?.init();
        anjSealInit.value = true;
      }
    } catch (error) {
      anjSealInit.value = false;
      $rollbar.error('Error initializing Anjouan iGaming License', error);
    }
  }, 1000);
});
</script>
