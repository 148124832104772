import gql from 'graphql-tag';

export const ALL_NFT = gql`
  query ALL_NFT {
    allNft {
      _id
      questions {
        _key
        _type
        question
        answerRaw
      }
    }
  }
`;
