import { useRouter } from 'vue-router';

import { Log } from '@/core/logger';

export function usePostMessage() {
  const authStore = useAuthStore();
  const { completeLogin, getUser, } = authStore;
  const { isUserLogged, user, } = storeToRefs(authStore);

  const uiStore = useUiStore();
  const { toastNotification, showConnectModal, activeGameProviderRef, replayModalUrl, } = storeToRefs(uiStore);

  const router = useRouter();
  const { $gtmCustomEvent, } = useNuxtApp();

  onMounted(() => {
    window.addEventListener('message', (e) => {
      onMessageReceived(e);
    });
  });

  async function checkUserSession() {
    // Check Cognito tokens in localStorage
    const cognitoPrefix = 'CognitoIdentityServiceProvider';
    const allCognitoEntries = Object.entries(localStorage)
    .filter(([key,]) => key.startsWith(cognitoPrefix));

    // Find the Client ID (second part of the key)
    const clientId = allCognitoEntries
    .map(([key,]) => key.split('.')[1])
    .find(id => id);

    if (!clientId) {
      return false;
    }

    // Find the last authenticated user
    const lastAuthUserKey = `${cognitoPrefix}.${clientId}.LastAuthUser`;
    const lastAuthUser = localStorage.getItem(lastAuthUserKey);

    if (!lastAuthUser) {
      return false;
    }

    // Check for specific token entries for this user
    const userTokens = allCognitoEntries
    .filter(([key,]) => {
      return key.includes(lastAuthUser)
               && (key.includes('accessToken')
                || key.includes('idToken'));
    });

    const tokenState = {
      hasTokens: userTokens.length > 0,
      hasAccessToken: userTokens.some(([key,]) => key.includes('accessToken')),
      hasIdToken: userTokens.some(([key,]) => key.includes('idToken')),
    };

    if (!tokenState.hasTokens || !tokenState.hasAccessToken || !tokenState.hasIdToken) {
      return false;
    }

    try {
      const userAvailable = await getUser(true);

      if (!userAvailable) {
        return false;
      }

      const sessionState = {
        hasSession: !!user.value?.signInUserSession,
        hasToken: !!user.value?.signInUserSession?.accessToken?.jwtToken,
      };

      if (!sessionState.hasSession || !sessionState.hasToken) {
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  async function onAuthComplete(data) {
    if (data.source) {
      data.source.postMessage({ action: 'authCompleteReceived', }, window.origin);
    }

    const firstLogin = localStorage.getItem('SocialFirstLogin') === 'true';

    let sessionValid = false;
    for (let i = 0; i < 3; i++) {
      sessionValid = await checkUserSession();
      if (sessionValid) { break; }

      if (!sessionValid && i < 2) {
        await new Promise((resolve) => {
          const checkAuth = () => {
            if (isUserLogged.value) {
              resolve();
            }
          };
          const unwatch = watch(isUserLogged, checkAuth);
          setTimeout(() => {
            unwatch();
            resolve();
          }, 5000);
        });
      }
    }

    if (!sessionValid) {
      uiStore.toastNotification = {
        type: 'warning',
        title: 'Login Status',
        content: 'Session initialization in progress. Please try refreshing the page.',
        closeAfter: 5000,
      };
      return;
    }

    try {
      await (firstLogin
        ? completeLogin(true, true)
        : completeLogin(false, true));

      if (firstLogin) {
        $gtmCustomEvent({ event: 'sign_up', action: 'success', social: true, });
      }
    } catch (error) {
      uiStore.toastNotification = {
        type: 'error',
        title: 'Login Error',
        content: 'There was an error completing your login. Please try refreshing the page.',
        closeAfter: 5000,
      };
    }
  }

  async function onMessageReceived(event) {
    if (activeGameProviderRef.value && event.data[activeGameProviderRef.value]) {
      const { type, url = null, } = event.data[activeGameProviderRef.value];
      if (type === 'OPEN_WINDOW' && url) {
        replayModalUrl.value = url;
        return;
      }
      if (type === 'GAME_CLOSED' && replayModalUrl.value) {
        replayModalUrl.value = null;
        return;
      }
      if (type === 'GAME_CLOSED') {
        await navigateTo('/games');
        return;
      }
    }
    if (event.origin !== window.origin) {
      return;
    }

    switch (event?.data?.action) {
      case 'authComplete':
        await onAuthComplete({ ...event.data, source: event.source, });
        break;
      case 'authError':
        onAuthError(event.data);
        break;
    }
  }

  function onAuthError(data) {
    showConnectModal.value = false;
    router.push('/');
    toastNotification.value = {
      type: 'error',
      title: data.title,
      content: data.message,
      closeAfter: 5000,
    };

    if (data.error) { Log.error('🔒 Auth Error...', data.error); }
  }
}
