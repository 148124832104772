import gql from 'graphql-tag';

export const ALL_GAMES = gql`
  query ALL_GAMES {
    allGames {
      slug {
        _key
        _type
        current
        source
      }
      mainImage {
        asset {
          url
        }
      }
      iframeUrl
      title
      _id
      rtp
      gameVolatility
    }
  }
`;
