import { storeToRefs } from 'pinia';
import { Log } from '@/core/logger';

import { useUserStore } from '@/store/user';
import { useAuthStore } from '@/store/auth';
import { useLocationStore } from '@/store/location';
import { useUiStore } from '@/store/ui';

export function useExponea() {
  const userStore = useUserStore();
  const { userData, } = storeToRefs(userStore);

  const uiStore = useUiStore();
  const { showTxToastNotification, } = uiStore;

  const authStore = useAuthStore();
  const { user, wallet, authType, } = storeToRefs(authStore);

  const locationStore = useLocationStore();
  const { country, } = storeToRefs(locationStore);

  const { $dayjs, $rollbar, $api, } = useNuxtApp();

  const isFeatureEnabled = computed(() => userData.value?.metadata?.crmProviderOverride === 'Bloomreach');

  async function initialiseServiceWorker() {
    if ('serviceWorker' in navigator) {
      try {
        await navigator.serviceWorker.register('/service-worker.js?url', { scope: '/', updateViaCache: 'imports', });
        Log.log('🔒 Service Worker Registered');
      } catch (error) {
        Log.log('🔒 Service Worker registration failed', error);
      }
    }
  }

  function exponeaIdentify() {
    const exponeaParams = getConnectionParam();
    if (typeof exponea !== 'undefined' && isFeatureEnabled.value) {
      exponea.identify(
        {
          customer_id: userData.value.secureId,
        },
        {
          ...exponeaParams,
          country: country.value,
          timestamp: $dayjs(),
        }
      );

      exponea.track('login', {
        ...exponeaParams,
        country: country.value,
        timestamp: $dayjs(),
      });
    }
  }

  function exponeaAccountRegistration() {
    const exponeaParams = getConnectionParam();
    const registrationParams = getRegistrationParam();

    exponea && exponea.track('registration', {
      ...exponeaParams,
      ...registrationParams,
      country: country.value,
      timestamp: nuxtApp.$dayjs(),
    });
  }

  function getConnectionParam() {
    const params = {
      Social: {
        connection: user.value?.attributes?.email,
        connection_value: user.value?.attributes?.['custom:external_provider'],
      },
      Web3: {
        connection: wallet.value?.address,
        connection_value: wallet.value?.name,
      },
      PasswordlessEmail: {
        connection: user.value?.attributes?.email,
        connection_value: 'Email',
      },
    };

    return params[authType.value] || {};
  }

  function requestPushNotification() {
    if (!isFeatureEnabled) { return; }

    setTimeout(() => {
      exponea && exponea.notifications.isAvailable((available) => {
        if (available) {
          Log.log('🔒 Browser supports push notifications!');
          exponea.notifications.subscribe(
            (status) => {
              if (status === 'subscribed') {
                try {
                  $api('/user/setting/notification', {
                    method: 'PUT',
                    body: {
                      promotion: [],
                      notifications: [
                        {
                          channel: 'Push',
                          settings: {
                            enabled: true,
                            account: true,
                            marketing: true,
                            prize: true,
                          },
                        },
                      ],
                    },
                  });
                  showTxToastNotification('Complete', 'Updating Notification Consent Settings');
                } catch (err) {
                  $rollbar.error('Error sending consent Settings', err);
                }
              } else {
                try {
                  $api('/user/setting/notification', {
                    method: 'PUT',
                    body: {
                      promotion: [],
                      notifications: [
                        {
                          channel: 'Push',
                          settings: {
                            enabled: true,
                            account: true,
                            marketing: false,
                            prize: true,
                          },
                        },
                      ],
                    },
                  });
                  Log.log('🔒 User has blocked push notifications');
                } catch (err) {
                  $rollbar.error('Error sending consent Settings', err);
                }
                Log.log('🔒 Failed to subscribe the user to push notifications:', status);
              }
            },
            { push_notifications: true, }
          );
        } else {
          Log.log('🔒 Browser does NOT supports push notifications!');
        }
      });
    }, 500);
  }

  return {
    isFeatureEnabled,
    exponeaAccountRegistration,
    exponeaIdentify,
    initialiseServiceWorker,
    requestPushNotification,
  };
}
