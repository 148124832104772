import { defineStore } from 'pinia';

const REFERRAL_ROOT_PATHS = ['referral', 't',];

export const useReferralStore = defineStore({
  id: 'referral-store',
  state: () => {
    return {
      referralUser: null,
      isProcessing: false,
      error: null,
    };
  },
  actions: {
    async init(to) {
      if (this.isProcessing) { return; }
      this.isProcessing = true;
      this.error = null;
      const router = useRouter();
      try {
        const currentPath = to.path.split('/').filter(Boolean);
        const queries = { ...to.query, };

        if (queries.hidePopup) {
          localStorage.setItem('welcomeModalHide3', true);
        }

        if (currentPath.length === 2 && REFERRAL_ROOT_PATHS.includes(currentPath[0])) {
          const data = await this.fetchReferrerBySlug(currentPath[1], true);
          if (!data) {
            router.push('/');
            return;
          }
          await this.handleReferralRedirect(data, to, queries);
        } else if (to.query.a) {
          const data = await this.fetchReferrerByDisplayName(to.query.a, to.query.t || '', true);
          if (!data) {
            router.push('/');
            return;
          }
          await this.handleReferralRedirect(data, to, queries);
        } else if (to.meta.checkRaf && to.params.id) {
          const data = await this.fetchReferrerByDisplayName(to.params.id, to.query.t || '', true);
          if (!data) {
            router.push('/');
            return;
          }
          await this.handleReferralRedirect(data, to, queries, false);
        }
      } catch (err) {
        this.error = err;
        if (to.path !== '/' && !to.path.startsWith('/error')) {
          router.push('/');
        }
      } finally {
        this.isProcessing = false;
      }
    },

    async handleReferralRedirect(data, to, queries, shouldRedirect = true) {
      const router = useRouter();
      const referralCookie = useCookie('referredCode', { maxAge: 60 * 60 * 24 * 30, });
      referralCookie.value = data?.code;

      if (shouldRedirect) {
        const redirectPath = this.getRedirect(data, to);
        const redirectQuery = { ...queries, };
        delete redirectQuery.a;
        delete redirectQuery.t;
        delete redirectQuery.c;
        delete redirectQuery.competition;
        delete redirectQuery.r;

        await router.push({
          path: redirectPath,
          query: redirectQuery,
        });
      }
    },

    getRedirect(data, to) {
      const compId = to.query.c || to.query.competition;
      if (compId) { return `/competition/${compId}`; }

      const redirection = to.query.r;
      if (redirection) { return `/${redirection}`; }

      if (data?.redirect) {
        const redirectParts = data.redirect.split(':');
        if (redirectParts[0] === 'sweepstake' && redirectParts.length > 1) {
          return `/competition/${redirectParts[1]}`;
        }
      }
      return '/';
    },
    async fetchReferrerBySlug(slug, click) {
      const nuxtApp = useNuxtApp();
      try {
        return await nuxtApp.$api(`/referral/public/slug/${slug}?clicked=${!!click}`, { method: 'GET', });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        throw err;
      }
    },
    async fetchReferrerByDisplayName(value, source, click) {
      const nuxtApp = useNuxtApp();
      const query = {};

      if (source) {
        query.source = source;
      }

      if (click) {
        query.clicked = true;
      }

      try {
        return await nuxtApp.$api(`/referral/public/user/${value}`, { method: 'GET', query, });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        throw err;
      }
    },
    async fetchReferralStatistics() {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/referral/statistics', { method: 'GET', });
        return data;
      } catch (err) {
        // nuxtApp.$rollbar.error('method failed', err); // TODO: temporarily disabled. Turn on when BE will fix timeout's
        throw err;
      }
    },
    async fetchReferralTrackerStatistics(dateFrom, dateTo, skip = 0, take = 50) {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/referral/statistics/tracker', {
          method: 'GET',
          query: {
            dateFrom,
            dateTo,
            skip,
            take,
          },
        });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        throw err;
      }
    },
    async getAdminReferralTrackers() {
      const nuxtApp = useNuxtApp();
      try {
        return await nuxtApp.$api('/referral/statistics/admin/trackers', { method: 'GET', });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        throw err;
      }
    },
    fetchAdminReferralTrackersBySlug(slug) {
      return useNuxtApp().$api(`/referral/statistics/admin/trackers/${slug}`, { method: 'GET', });
    },
    async fetchReferralUser() {
      const nuxtApp = useNuxtApp();
      try {
        return await nuxtApp.$api('/referral/user', { method: 'GET', });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        throw err;
      }
    },
    async getReferrals(page) {
      const nuxtApp = useNuxtApp();
      try {
        return await nuxtApp.$api('/referral', {
          method: 'GET',
          params: {
            page,
            pageSize: 40,
          },
        });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        throw err;
      }
    },
    async getRewards(page = 1, pageSize = 20) {
      const nuxtApp = useNuxtApp();
      try {
        return await nuxtApp.$api(`/referral/reward?page=${page}&pageSize=${pageSize}`, { method: 'GET', });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        throw err;
      }
    },
    /**
     * NB: Not currently used
     */
    getLegacyReferralLink(tracker) {
      return `${window.location.origin}/t/${tracker.slug}`;
    },
    getReferralLink(displayName, source, isRafLink = true) {
      let link = `${window.location.origin}/${displayName.toLowerCase()}/`;

      if (source || !isRafLink) {
        link = `${window.location.origin}?a=${displayName.toLowerCase()}`;
        link += source ? `&t=${source}` : '';
      }

      return link;
    },
    setTrackingCookie(tracker, route, redirect = true) {
      const referralCookie = useCookie('referredCode', { maxAge: 60 * 60 * 24 * 30, });
      referralCookie.value = tracker?.code;
      if (redirect) {
        window.location.href = this.getRedirect(tracker, route);
      }
    },
  },
});
