<template>
  <div id="app">
    <NuxtLayout>
      <NuxtPage/>
    </NuxtLayout>
    <ClientOnly>
      <transition name="bottom-pop">
        <LazyMinigameMain v-if="showGameContainer" display-mode="floating"/>
      </transition>

      <transition :name="toastNotification?.delayed ? 'top-pop-delayed' : 'top-pop'">
        <LazyToastMain v-if="toastNotification"/>
      </transition>
      <LazyModalDepositMain v-if="showDepositModal"/>
      <LazyModalCreateTx v-if="showWalletModal"/>

      <ModalReloadMain v-if="showReloadModal"/>
      <LazyModalErrorMain v-if="showErrorModal"/>
      <LazyModalWhyConnectMain v-if="showWhyConnectModal"/>
      <LazyModalMethMain v-if="showMethModal"/>
      <LazyModalConnectMain v-if="showConnectModal && !showSignatureModal"/>
      <LazyModalLoginEmail v-if="showLoginEmailModal"/>
      <LazyModalLoginEmailOneTimeLink v-if="showLoginEmailOneTimeLinkModal"/>
      <LazyModalNetworkMain v-if="showNetworkModal"/>
      <LazyModalSignatureMain v-if="showSignatureModal"/>
      <LazyModalInfoMain v-if="showInfoModal"/>
      <LazyModalMediaMain v-if="showMediaModal"/>
      <LazyModalMediaIntroduction v-if="showIntroductionModal"/>
      <LazyModalGameVolatility v-if="showVolatilityModal"/>
      <LazyModalAccountMain v-if="showInfoRegisterModal"/>
      <LazyModalAccountAddAlias v-if="showAddAliasModal"/>
      <LazyModalDepositAccountMain v-if="showDepositAccountModal"/>
      <LazyModalCmsPromo v-if="showPromoModal"/>
      <LazyModalBridge v-if="showBridgeModal"/>
      <LazyModalBonusValidationRedeem v-if="showRedeemModal"/>
      <LazyModalReplay v-if="replayModalUrl"/>
      <LazyModalTradingHow v-if="showTradingHowModal"/>
      <LazyModalTradingRoi v-if="showTradingRoiModal"/>
      <LazyModalTradingOrderForm v-if="showTradingOrderFormModal"/>
      <LazyModalTradingOrderDetail v-if="showTradingOrderDetailModal"/>
      <LazyModalTradingVerifiablyFair v-if="showTradingVerifiablyFairModal"/>
      <LazyModalTradingLicense v-if="showTradingLicenseModal"/>
      <LazyModalGameFairness v-if="showFairnessModal"/>
      <LazyModalRaffleWeeklySummary v-if="showWeeklyRaffleModal"/>
    </ClientOnly>
  </div>
</template>

<script setup>
import { usePostMessage } from '@/composables/usePostMessage';
import { useIntercom } from '@/composables/useIntercom';
import { walletModalModeTypes, walletModalSubModeTypes } from '@/types/Wallet';
import { useExponea } from '@/composables/useExponea';

const uiStore = useUiStore();
const {
  showDepositModal,
  showWalletModal,
  showErrorModal,
  showReloadModal,
  showWhyConnectModal,
  showInfoModal,
  showConnectModal,
  showLoginEmailModal,
  showLoginEmailOneTimeLinkModal,
  showNetworkModal,
  showSignatureModal,
  showMethModal,
  showMediaModal,
  toastNotification,
  showInfoRegisterModal,
  showDepositAccountModal,
  showAddAliasModal,
  showBridgeModal,
  showIntroductionModal,
  showPromoModal,
  showVolatilityModal,
  showRedeemModal,
  walletModalData,
  replayModalUrl,
  showTradingHowModal,
  showTradingRoiModal,
  showTradingOrderFormModal,
  showTradingOrderDetailModal,
  showTradingVerifiablyFairModal,
  showTradingLicenseModal,
  showFairnessModal,
  showWeeklyRaffleModal,
} = storeToRefs(uiStore);
const { openTxModal, } = uiStore;

const route = useRoute();
const router = useRouter();

const bankingStore = useBankingStore();
const {
  balances,
} = storeToRefs(bankingStore);

const authStore = useAuthStore();
const { isUserLogged, } = storeToRefs(authStore);

// This may need rethinking to a less global approach
const competitions = useCompetitionStore();
const { getStoredPasswords, } = competitions;

const locationStore = useLocationStore();

const miniGameStore = useMinigamesStore();
const { showGameContainer, } = storeToRefs(miniGameStore);

const {
  requestPushNotification,
  initialiseServiceWorker,
  isFeatureEnabled,
} = useExponea();

const { $api, $rollbar, } = useNuxtApp();

onMounted(() => {
  nextTick(() => {
    document.body.classList.add('ready');
  });

  // Get any stored password from localStorage for sweepstake packages
  getStoredPasswords();
});

watch(() => isUserLogged.value, (newVal, oldVal) => {
  if (!oldVal && newVal) {
    usePlatformStarter();
    activeAccountCheck();
    locationStore.init();

    // TODO: remove feature flag when going live
    if (!isFeatureEnabled.value) {
      requestPushNotification();
    }
  }
});

watch(balances, (newVal) => {
  if (newVal.length > 0) {
    walletModalOnInit();
  }
});

function walletModalOnInit() {
  // metawin.com?modal=custodialDeposit&crypto=USDT
  // metawin.com?modal=deposit&crypto=USDT
  // metawin.com?modal=buyCrypto&crypto=USDT
  // metawin.com?modal=boostcode&code=TEST_001 feature flagged for dev and qa
  // metawin.com?modal=buyCrypto etc etc
  const modalType = route.query.modal;
  if (!modalType || !['buyCrypto', 'deposit', 'custodialDeposit', 'boostcode',].includes(modalType) || !isUserLogged.value) {
    return;
  }

  if (modalType === 'boostcode') {
    walletModalData.value = { code: route.query?.code, };
    router.replace({ query: {}, });
    showRedeemModal.value = true;

    return;
  }

  const mode = route.query.modal === 'deposit' ? walletModalModeTypes[0] : walletModalModeTypes[1];
  const currency = route.query.crypto;
  const depositCode = route.query.depositcode;
  router.replace({ query: {}, });
  setTimeout(() => {
    openTxModal(mode, walletModalSubModeTypes[0], currency, depositCode);
  }, 1000);
}

async function activeAccountCheck() {
  const hasSeenIntroModal = sessionStorage.getItem('intro-modal-seen');

  // Checks to see if user has viewed the introductionModal
  if (isUserLogged.value && (hasSeenIntroModal === 'false' || !hasSeenIntroModal)) {
    try {
      const res = await $api('/user/participation', { method: 'GET', });
      const hasParticipated = res?.hasDeposited || res?.hasEnteredGames || res?.hasEnteredSweepstake;

      showIntroductionModal.value = !hasParticipated;
      sessionStorage.setItem('intro-modal-seen', 'true');
    } catch {
      $rollbar.error('Failed to fetch user participation status', err);
    }
  }
}

// Setup social login event listeners
usePostMessage();
useIntercom();
initialiseServiceWorker();
</script>
