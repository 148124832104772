import revive_payload_client_4sVQNw7RlN from "/home/circleci/project/projects/main/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/circleci/project/projects/main/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/circleci/project/projects/main/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/home/circleci/project/projects/main/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/circleci/project/projects/main/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/circleci/project/projects/main/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_W5VNcGB8FU from "/home/circleci/project/projects/main/node_modules/nuxt-3-intercom/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/circleci/project/projects/main/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/circleci/project/projects/main/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import _01_setup_SSHq4oUu8F from "/home/circleci/project/projects/main/plugins/01.setup.js";
import api_client_FrfnO43kJt from "/home/circleci/project/projects/main/plugins/api.client.js";
import auth_client_E5M9zMdRCQ from "/home/circleci/project/projects/main/plugins/auth.client.js";
import chartIQ_Oho4IKeYUF from "/home/circleci/project/projects/main/plugins/chartIQ.js";
import click_outside_oytHm5ka8v from "/home/circleci/project/projects/main/plugins/click-outside.js";
import clipboard_un1pNMmI0D from "/home/circleci/project/projects/main/plugins/clipboard.js";
import dayjs_FoMn5Zigri from "/home/circleci/project/projects/main/plugins/dayjs.js";
import dragscroll_LfsIjoNP97 from "/home/circleci/project/projects/main/plugins/dragscroll.js";
import errorHandler_cfkZ75xwSQ from "/home/circleci/project/projects/main/plugins/errorHandler.js";
import featureFlag_VqnH5YRHeP from "/home/circleci/project/projects/main/plugins/featureFlag.js";
import gtm_client_KEcT5dOoKs from "/home/circleci/project/projects/main/plugins/gtm.client.js";
import gtmCustom_client_r2TZ4QA37D from "/home/circleci/project/projects/main/plugins/gtmCustom.client.js";
import hooks_client_5sZD5G2zib from "/home/circleci/project/projects/main/plugins/hooks.client.js";
import imgSrcResize_COZyOqsLQ0 from "/home/circleci/project/projects/main/plugins/imgSrcResize.js";
import moonpay_UrW2F3lgLS from "/home/circleci/project/projects/main/plugins/moonpay.js";
import rollbar_client_9QbH7LcSuf from "/home/circleci/project/projects/main/plugins/rollbar.client.js";
import transforms_8czJ5hy2ID from "/home/circleci/project/projects/main/plugins/transforms.js";
import utilities_hitwLOQF1j from "/home/circleci/project/projects/main/plugins/utilities.js";
import villus_ksHeuvhLCk from "/home/circleci/project/projects/main/plugins/villus.js";
import vue_tippy_hWNFm8xa76 from "/home/circleci/project/projects/main/plugins/vue-tippy.js";
import vue3_mq_w8uKXeQoCw from "/home/circleci/project/projects/main/plugins/vue3-mq.js";
import welcome_client_6Aw4U6bMaa from "/home/circleci/project/projects/main/plugins/welcome.client.js";
import wyre_Fbb1L6CQDX from "/home/circleci/project/projects/main/plugins/wyre.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_W5VNcGB8FU,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  _01_setup_SSHq4oUu8F,
  api_client_FrfnO43kJt,
  auth_client_E5M9zMdRCQ,
  chartIQ_Oho4IKeYUF,
  click_outside_oytHm5ka8v,
  clipboard_un1pNMmI0D,
  dayjs_FoMn5Zigri,
  dragscroll_LfsIjoNP97,
  errorHandler_cfkZ75xwSQ,
  featureFlag_VqnH5YRHeP,
  gtm_client_KEcT5dOoKs,
  gtmCustom_client_r2TZ4QA37D,
  hooks_client_5sZD5G2zib,
  imgSrcResize_COZyOqsLQ0,
  moonpay_UrW2F3lgLS,
  rollbar_client_9QbH7LcSuf,
  transforms_8czJ5hy2ID,
  utilities_hitwLOQF1j,
  villus_ksHeuvhLCk,
  vue_tippy_hWNFm8xa76,
  vue3_mq_w8uKXeQoCw,
  welcome_client_6Aw4U6bMaa,
  wyre_Fbb1L6CQDX
]