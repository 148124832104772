
import { getConfig } from '@/utils/getConfig';

export function useIntercom() {
  const { $intercom, $dayjs, $api } = useNuxtApp();
  const authStore = useAuthStore();
  const userStore = useUserStore();

  const { isUserLogged } = storeToRefs(authStore);
  const { userData, userProfile } = storeToRefs(userStore);

  const isUserReady = computed(() => !!(isUserLogged.value && userData.value));
  const intercomEnabled = computed(() => getConfig('INTERCOM_ENABLED'));
  const unreadCount = ref(0);

  async function initIntercom(params) {
    if (!isUserLogged.value || !intercomEnabled.value) {
      return;
    }

    try {
      await userStore.getUserProfile();

      const identityData = await $api('/support/intercom/identity', {
        method: 'GET'
      });

      $intercom.update({
        ...params,
        ...identityData.user_data,
        user_hash: identityData.user_hash,
        avatar: userData.value?.avatarUrl
          ? {
            type: 'avatar',
            image_url: userData.value.avatarUrl,
          }
          : null,
      });

      $intercom.call('onUnreadCountChange', (val) => {
        unreadCount.value = val;
      });
    } catch (error) {
      console.error('Failed to initialize Intercom with identity verification:', error);
    }
  }

  function showIntercom() {
    if (!intercomEnabled.value) {
      return;
    }
    $intercom.show();
  }

  function hideIntercom() {
    if (!intercomEnabled.value) {
      return;
    }
    $intercom.hide();
  }

  watch(isUserReady, (newVal, oldVal) => {
    if (newVal && !oldVal) {
      initIntercom();
    } else if (!newVal && oldVal) {
      $intercom.hide();
      $intercom.shutdown();
    }
  }, { immediate: true });

  return {
    initIntercom,
    showIntercom,
    hideIntercom,
    unreadCount,
    intercomEnabled,
  };
}
