import gql from 'graphql-tag';

export const ALL_LEADERBOARDS =  gql`
  query ALL_LEADERBOARDS {
    allLeaderboards(where: { show: { eq: true } }, sort: { orderRank: ASC }) {
      _id
      name
      leaderboardId
      type
      gameNameOrId
      show
      orderRank
    }
  }
`;
