import blocksToHtml from '@sanity/block-content-to-html';
import { cache, createClient, defaultPlugins, useQuery } from 'villus';
import { multipart } from '@villus/multipart';

export default defineNuxtPlugin((nuxtApp) => {
  const { SANITY_PROJECT_ID, SANITY_API_VERSION, SANITY_TAG, CMS_DATASET } =
    nuxtApp.$config.public;

  const projectId = SANITY_PROJECT_ID || 'edcyyirq';
  const apiVersion = SANITY_API_VERSION || 'v2023-08-01';
  const tag = SANITY_TAG || 'default';
  const dataset = CMS_DATASET || 'development';

  const handleCache = cache();

  const villus = createClient({
    url: `https://${projectId}.api.sanity.io/${apiVersion}/graphql/${dataset}/${tag}`,
    cachePolicy: 'cache-first',
    use: [handleCache, multipart(), ...defaultPlugins()],
  });

  const useVillusQuery = (query, variables = {}) => {
    const { execute } = useQuery({
      client: villus,
      query,
      variables,
      onError: (error) => {
        console.error('Villus Errors:', error);
        throw new Error('GraphQL query resulted in errors.');
      }
    });
    
    return execute;
  };

  return {
    provide: {
      villus,
      sanityBlocksToHtml: (blocks, serializers = null) => {
        const defaultSerializers = {
          types: {
            button: (props) => {
              const { text, url } = props.node;
              return `<button class="flex"><a href="${url}">${text}</a></button>`;
            },
          },
        };
        return blocksToHtml({
          dataset,
          projectId,
          serializers: { ...defaultSerializers, ...serializers },
          blocks,
        });
      },
      useVillusQuery
    },
  };
});
