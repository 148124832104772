import { Amplify, Auth } from 'aws-amplify';

export default defineNuxtPlugin(async(nuxtApp) => {
  const AWS_CONFIG = {
    Auth: {
      region: nuxtApp.$config.public.AWS_REGION,
      userPoolId: nuxtApp.$config.public.USER_POOL_ID,
      userPoolWebClientId: nuxtApp.$config.public.USER_POOL_CLIENT_ID_FEDERATED,
    },
    oauth: {
      domain: nuxtApp.$config.public.FEDERATED_IDENTITY_DOMAIN,
      redirectSignIn: nuxtApp.$config.public.FEDERATED_IDENTITY_PROTOCOL + '://' + window.location.host + nuxtApp.$config.public.FEDERATED_IDENTITY_LOGIN_PATH,
      redirectSignOut: nuxtApp.$config.public.FEDERATED_IDENTITY_PROTOCOL + '://' + window.location.host + nuxtApp.$config.public.FEDERATED_IDENTITY_LOGOUT_PATH,
      responseType: 'code',
    },
  };

  // Helper function to check initialization status
  const waitForAmplifyInit = async() => {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (error) {
      if (error.message !== 'No current user') {
        return false;
      }
      return true;
    }
  };

  Amplify.configure(AWS_CONFIG);
  await waitForAmplifyInit();

  return {
    provide: {
      auth: Auth,
    },
  };
});
