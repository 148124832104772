import gql from 'graphql-tag';

export const ALL_NAV_COLLECTIONS = gql`
  query ALL_NAV_COLLECTIONS($collectionId: String!) {
    allNavCollections(where: { collectionId: { eq: $collectionId } }) {
      collectionId
      show
      navListItems {
        _id
        _type
        _createdAt
        _updatedAt
        _rev
        _key
        icon
        label
        linkTo
        show
        orderRank
      }
    }
  }
`;
