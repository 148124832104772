export default defineNuxtRouteMiddleware((to, from) => {
  const { $removeTrailingSlash, $config, } = useNuxtApp();
  // const sportsBettingStore = useSportsBettingStore();
  // const { previousSubPath, } = storeToRefs(sportsBettingStore);

  if (to.fullPath.includes('sports')) {
    return navigateTo('/');
  }

  // if (!from.fullPath.includes('sports') && !to.fullPath.includes('sports')) {
  //   previousSubPath.value = null;
  // }

  // Enforce trailing slash in path - matching AWS S3 hosting rules
  if (to.path !== '/' && !to.path.endsWith('/')) {
    return navigateTo({ path: `${to.path}/`, query: to.query, hash: to.hash, });
  }

  const pathTo = $removeTrailingSlash(to.path);

  // Maintenance mode redirect
  if ($config.public.MAINTENANCE_ENABLED && pathTo !== '/maintenance') {
    return navigateTo('/maintenance');
  } else if (!$config.public.MAINTENANCE_ENABLED && pathTo === '/maintenance') {
    return navigateTo('/');
  }

  if (!$config.public.SHOW_VIP && pathTo.includes('salon-rouge')) {
    return navigateTo('/');
  }

  if (!$config.public.NFT_HOLDER_ENABLED && pathTo.includes('meta-winners')) {
    return navigateTo('/');
  }

  // Hard redirects
  switch (pathTo) {
    case '/mw':
    case '/minigames':
      return navigateTo('/');
    case '/live-gaming':
      return navigateTo('/live-games');
    case '/p/cyber':
      return navigateTo('https://ivory-actual-moth-667.mypinata.cloud/ipfs/QmQxomyZWUWgBQvDyJ1HEFrr2EYwVpbwhWzhh5rKseX9pt/TokenRightsDocument.pdf', {
        external: true,
      });
  }
});
