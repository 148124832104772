import { StatsigClient } from '@statsig/js-client';

export default defineNuxtPlugin(async(nuxtApp) => {
  const userStore = useUserStore();
  const { userData, } = storeToRefs(userStore);

  function getEnvironment() {
    switch (nuxtApp.$config.public.ENV_NAME.toLowerCase()) {
      case 'prod':
        return 'production';
      case 'qa':
        return 'staging';
      default:
        return 'development';
    }
  }

  const user = { userID: userData.value?.id || 0, };

  const options = {
    environment: { tier: getEnvironment(), },
  };

  const myStatsigClient = new StatsigClient(nuxtApp.$config.public.STATSIG_CLIENT_SDK_KEY, user, options);
  await myStatsigClient.initializeAsync();

  userStore.$subscribe(async(_, state) => {
    if (state.userData?.id) {
      await myStatsigClient.updateUserAsync({ userID: state.userData.id, });
    }
  });

  return {
    provide: {
      statsig: myStatsigClient,
    },
  };
});
