import gql from 'graphql-tag';

export const ALL_TANNOID_MESSAGES = gql`
  query ALL_TANNOID_MESSAGES {
    allTannoidMessages {
      contentList {
        _key
        _type
        contentRaw
        loginRequired
        payload
        higlightedMessage
      }
    }
  }
`;
