import gql from 'graphql-tag';

export const ALL_FILTER_CATEGORIES_LIVE = gql`
  query ALL_FILTER_CATEGORIES_LIVE {
    allFilterCategoriesLive {
      _id
      _type
      _createdAt
      _updatedAt
      _rev
      _key
      category
      items {
        _key
        _type
        itemName
        itemValue
      }
    }
  }
`;
