import gql from 'graphql-tag';

export const ALL_CONTENT_PAGE = gql`
  query ALL_CONTENT_PAGE($routeName: String!) {
    allContentPage(where: { routeName: { eq: $routeName } }) {
      _id
      contentRaw
    }
  }
`;
