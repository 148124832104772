import { defineStore } from 'pinia';
import { Log } from '@/core/logger';
import { getConfigSplit } from '@/utils/getConfig';

export const useDepositAccountStore = defineStore({
  id: 'deposit-account-store',
  state: () => {
    return {
      accounts: [],
      currencies: getConfigSplit('DEPOSIT_ACCOUNT_CURRENCY_OPTIONS'),
    };
  },
  actions: {
    async init() {
      try {
        const response = await useNuxtApp().$api('/deposit/account', {
          method: 'GET',
        });
        this.accounts = response;
      } catch (err) {
        this.accounts = [];
        Log.error(err);
      }
    },
    async add(currency, provider, network) {
      try {
        const account = await useNuxtApp().$api(`/deposit/account/${provider.toLowerCase()}/${currency}`, {
          method: 'POST',
          params: {
            network,
          },
        });
        this.accounts.push(account);
        return account;
      } catch (err) {
        Log.error('Deposit account error.', err.data);
        throw new Error(err.data?.name || 'An unexpected error occurred.');
      }
    },
    async getSignedURL(url, network) {
      try {
        const signedURL = await useNuxtApp().$api('payment/onramp/moonpay', {
          method: 'POST',
          body: JSON.stringify({ url, network, }),
        });
        return signedURL;
      } catch (err) {
        Log.error('error.', err.data);
      }
    },
  },
});
